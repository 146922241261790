import React,{ useState,useEffect } from "react"
import tw from 'twin.macro'
import A from './styles/A'

const Header = (props) => {
  const [visible, setVisible] = useState(false);
  const navFun = (e) => {
    setVisible(!visible)
    e.nativeEvent.stopImmediatePropagation();
  }
  useEffect(()=>{
    if(window){
      window.document.addEventListener('click', ()=>{
        setVisible(false)
      });
    }
  },[])
  const styles = {
      button:(e)=>[
          tw`flex items-center px-[15px] py-1 rounded-full`,
          e.indexOf('text-white') > -1 && tw`hover:bg-white`,
          e.indexOf('text-black') > -1 && tw`hover:bg-black`,
          tw`hover:bg-opacity-10`
      ]
  }

  return (
    <>
      <header className={`fixed top-0 left-1/2 w-full bg-white bg-opacity-95 xl:bg-opacity-0 ${visible?'!bg-opacity-100':''} backdrop-filter xl:backdrop-blur-none backdrop-blur-lg transform -translate-x-1/2 z-10 h-[90px]`}>
        <div className="flex justify-between items-center container mx-auto h-full xl:px-4">
          <a href="/" className="w-1/4"><svg viewBox="0 0 670.3 120" className="w-[132px] sm:w-24"> <path d="M570.9 65.6a23.5 23.5 0 0 0-42.8 10.9l-.1 1.4 43.6-11.1-.7-1.2zm-19.3 36.6c8.7 0 16.7-4.8 20.7-12.5l10.3 10.3a37.5 37.5 0 1 1-61.9-42.5 37.5 37.5 0 0 1 68.4 18.6l-55.9 15-1.3.4.8 1.1a22.5 22.5 0 0 0 18.9 9.6m-393.2 0c8.7 0 16.7-4.8 20.8-12.5l10.3 10.3a37.5 37.5 0 1 1-61.9-42.5A37.5 37.5 0 0 1 196 76.1l-55.9 15-1.4.4.8 1.1a22.8 22.8 0 0 0 18.9 9.6m19.4-36.6a23.5 23.5 0 0 0-42.7 10.8l-.1 1.4 43.6-11.1-.8-1.1zm327.7 1.3.1 49.4h-14.1V69.1a13.8 13.8 0 0 0-27.6 0v47.2h-14.1V15.2l14.1 11v18.7l1.4-.7a28 28 0 0 1 40.2 21.9v.8zm-147.3-10h14.1v59.4h-14.1zm7-8a8.7 8.7 0 1 1 8.7-8.7c0 4.9-3.9 8.7-8.7 8.7m47.1-7.7c13.3 0 23.7 5.3 28.8 14.5l-13 7.6c.5-.3.4-.5.7-.3l-.8-.8a31.7 31.7 0 0 0-3.4-3 19.7 19.7 0 0 0-12.1-4.4c-9.5 0-15.9 3.8-15.9 9 0 3.8 2.3 5.2 9.2 6.4l10.9 1.7c16.3 2.6 24.9 9 24.9 21.2 0 14.1-11.9 23.4-29.6 23.4-13.9 0-26.8-7.9-29.2-17.9l12.4-7.1c2 3.8 3.5 5.9 5.6 7.5 9 6.4 25.1 3.8 26.6-4.1 1.1-6-3.2-8-16.1-10l-2.2-.3-2.2-.4c-2.7-.4-5.4-1-8.1-1.8-11.8-3.5-16.5-10.4-16.5-18.5 0-12.5 13.5-22.5 30-22.5m-154 27.8v47.2h-14.1V69.1a13.8 13.8 0 0 0-27.6 0v47.2h-14.1V69.1a28 28 0 0 1 48.1-19.4l.7.7.7-.7a28 28 0 0 1 48 16.2l.2 3v47.3H286V69.1a13.8 13.8 0 1 0-27.7.1M97.6 73h-40V56.8l41.8.1h16.1l.1 3.2a57.8 57.8 0 1 1-17-40.9L84 27.6A41.8 41.8 0 1 0 97.6 73m527.7-21.6a20.4 20.4 0 0 0-10.8 8.6 16.9 16.9 0 0 0-2.9 9.5v47.2h-14.1V69.6c0-12 6.9-23 17.8-28.1l10 9.9zM335 56.8h13.6v13.9H335v45.7h-14.1V70.6H307V56.8h13.9V45.4c0-12 6.9-23 17.8-28.1l10 10a20.4 20.4 0 0 0-10.8 8.6 16.9 16.9 0 0 0-2.9 9.5v11.4zm320.7-27.1L647.1 19h4.8c2.1 0 4.3-.6 4.3-3.1a3 3 0 0 0-1.2-2.5c-.9-.5-2-.7-3-.6h-5v17h-3.8V9.3h8.8c1.8 0 3.5 0 5.1.9 1.9 1.1 3 3.2 3 5.4 0 2-.9 3.9-2.5 5.2a7 7 0 0 1-3.5 1.3l6.2 7.6h-4.6z" /> <path d="M650.8 39a19.5 19.5 0 1 1 0-39 19.5 19.5 0 1 1 0 39zm0-35.3a15.8 15.8 0 0 0 0 31.6c8.7 0 15.8-7.1 15.8-15.8a15.9 15.9 0 0 0-15.8-15.8z" /> </svg></a>
          <ul className={`w-3/4 flex items-center text-xl xl:bg-white xl:fixed xl:top-[90px] xl:left-0 xl:w-screen xl:flex-col xl:h-screen xl:text-[56px] xl:leading-normal xl:px-4 ${visible?'xl:flex':'xl:hidden'}`}>
                <li className="xl:w-full"><A className="mr-10 xl:mr-0" href="/work">案例</A></li>
                <li className="xl:w-full"><A className="mr-10 xl:mr-0" href="/services">服务</A></li>
                <li className="xl:w-full"><A className="mr-10 xl:mr-0" href="/about">关于</A></li>
                <li className="xl:w-full"><A className="mr-10 xl:mr-0" href="/stories">想法</A></li>
                <li className="xl:w-full"><A className="mr-10 xl:mr-0" href="/jobs">职位</A></li>
                <li className="ml-auto xl:ml-0 xl:w-full"><A href="/contact">联系</A></li>
            </ul>
            <div className="hidden xl:block" onClick={navFun}>
              {
                visible?<svg fill="none" width="32" viewBox="0 0 32 32"><path stroke="currentColor" strokeLinecap="round" strokeWidth="1.5" d="M9.3 22.7 16 16m6.7-6.7L16 16m0 0L9.3 9.3M16 16l6.7 6.7" strokeLinejoin="round"></path></svg>
                :<svg fill="none" width="32" viewBox="0 0 32 32"><path stroke="currentColor" strokeLinecap="round" strokeWidth="1.5" d="M8 10.7h16M8 16h16M8 21.3h16"></path></svg>
              }
            </div>
        </div>
      </header>
    </>
  )
}

export default Header
