/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import Header from "./header"
import Footer from "./footer"
import "../style/common.css"
import AOS from 'aos'
import 'aos/dist/aos.css'

const Layout = (props) => {
  const pageQuery = useStaticQuery(graphql`
  query {
      allStrapiGlobal{
          edges {
           node {
              tracking
           }
         }
      }
  }`)
  const node = pageQuery.allStrapiGlobal.edges[0].node
  useEffect(()=>{
      AOS.init()
  },[])
  return (
    <div className="flex flex-col min-h-screen">
        {node.tracking?<Helmet>
          <script type="text/javascript" async defer>{node.tracking}</script>
        </Helmet>:null}
        <Header/>
        <main className="container mx-auto w-full">{props.children}</main>
        <Footer/>
    </div>
  )
}


export default Layout
