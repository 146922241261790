import tw, { styled,css } from 'twin.macro'

const A = styled.a(({ variant }) => [
  // The common button styles added with the tw import
  tw`relative`,
  css`transition: background-size 0.3s cubic-bezier(.25,.46,.45,.94);background-image:linear-gradient(currentColor,currentColor);background-position:100% 100%;background-size:0% 0.075em;background-repeat:no-repeat;padding-bottom:0.1em`,
  css`&:hover{background-position:0 100%;background-size:100% 0.07em;}`,
  variant === 'border-black' && css`background-image:linear-gradient(currentColor,currentColor),linear-gradient(#333,#333);`,
  (variant === 'border-gray' || variant === 'link') && css`background-image:linear-gradient(currentColor,currentColor),linear-gradient(#ccc,#ccc);`,
  (variant === 'border-gray' || variant === 'border-black' || variant === 'link') && css`background-position:100% 100%,100% 100%;background-repeat:no-repeat;background-size:0 1px,100% 1px;&:hover{background-position:0 100%,0 100%;background-size:100% 1px,100% 1px}`,
  (variant === 'more' || variant === 'add') && tw`after:content-none after:absolute after:right-0 after:top-1 after:w-[28px] after:h-[28px] pr-9 text-[28px] font-bold`,
  variant === 'more' && css`&:after{background:url('data:image/svg+xml;utf8,<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="28"><path d="M566 180a32 32 0 0 0-44 46l267 254H160a32 32 0 0 0 0 64h629L522 798a32 32 0 0 0 44 46l317-301a42 42 0 0 0 12-25 32 32 0 0 0 0-12 42 42 0 0 0-12-25L566 180z"/></svg>') no-repeat center center}`,
  variant === 'add' && css`&:after{background:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 1024 1024"><path d="M439 439H0v146h439v439h146V585h439V439H585V0H439v439z"/></svg>') no-repeat center center}`,
  variant === 'link' && tw`after:content-none after:absolute after:right-0 after:top-1 after:w-[18px] after:h-[18px] pr-5`,
  variant === 'link' && css`&:after{background:url('data:image/svg+xml;utf8,<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="18"><path d="M395 149h448c17 0 32 15 32 32s-15 32-32 32H395c-17 0-32-15-32-32s15-32 32-32z"/><path d="M875 181v448c0 17-15 32-32 32s-32-15-32-32V181c0-17 15-32 32-32s32 15 32 32z"/><path d="M832 239 228 843a31 31 0 0 1-45 0c-12-11-12-32-2-45l606-606c13-13 32-13 45 0s13 34 0 47z"/></svg>') no-repeat center center}`,

  // tw`relative overflow-hidden inline-flex`,
  // tw`after:content-none after:w-full after:h-[1px] after:origin-left after:transform after:-translate-x-full after:bg-black after:absolute after:-bottom-0 after:left-0`,
  // tw`before:content-none before:w-full before:transition-transform before:transition-duration[400ms] before:h-[1px] before:origin-right before:transform before:translate-x-full before:bg-black before:absolute before:-bottom-0 before:right-0`,
  // tw`hover:after:translate-x-0 hover:after:transition-transform hover:after:transition-duration[400ms] group-hover:after:translate-x-0 group-hover:after:transition-transform group-hover:after:transition-duration[400ms]`, 
  // tw`hover:before:translate-x-0 hover:before:bg-transparent group-hover:before:translate-x-0 group-hover:before:bg-transparent`, 
  
  // Use props to conditionally style your components
  // variant === 'more' && tw`after:h-[2px] before:h-[2px] pr-9 text-[28px] font-bold`,
  // variant === 'more' && css`background:url('data:image/svg+xml;utf8,<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="22"><path d="M760.768 576L384 931.84 469.76 1024 1024 515.392 474.048 0 384 98.88 753.6 448H0v128h760.768z"></path></svg>') no-repeat 100% 10px`,
  // variant === 'border-gray' && css`background:linear-gradient(currentColor,currentColor),linear-gradient(#ccc,#ccc) no-repeat;background-position:0 100%,0 100%;background-size:0 1px,100% 1px`,
  // variant === 'border-black' && css`background:linear-gradient(currentColor,currentColor),linear-gradient(#333,#333) no-repeat;background-position:0 100%,0 100%;background-size:0 1px,100% 1px`
])

export default A
