import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { A } from '../components/styles'

 export default function IndexPage({location,data}){
    const pageQuery = useStaticQuery(graphql`
    query {
        allStrapiGlobal{
            edges {
             node {
                behance
                copyright
                contact {
                  email
                  title
                  telphone
                }
                instagram
                weibo
                weixin
                work_email
             }
           }
        }
    }`)
    const node = pageQuery.allStrapiGlobal.edges[0].node
    return (
        <footer className="relative z-[2] w-full bg-black pt-20 pb-8 text-white">
            <div className="container mx-auto">
                <div className="pb-[110px] border-b border-white border-opacity-20 flex lg:pb-16">
                    <div className="grid grid-cols-3 gap-9 w-9/12 lg:grid-cols-1">
                        <div>
                            <h3 className="xl:text-lg text-xl">Got a project?</h3>
                            <a href="/contact" className="xl:text-[32px] text-[56px] hover:text-gray-dark transition duration-300">Let's talk</a>
                        </div>
                        <div>
                            <h3 className="xl:text-lg text-xl">Got talent?</h3>
                            <a href="/jobs" className="xl:text-[32px] text-[56px] hover:text-gray-dark transition duration-300">Join us</a>
                        </div>
                        <div>
                            <h3 className="xl:text-lg text-xl">Got time?</h3>
                            <a href="/subscribe" className="xl:text-[32px] text-[56px] hover:text-gray-dark transition duration-300">Subscribe</a>
                        </div>
                    </div>
                    <div className="w-3/12 flex justify-end"><a href="#" className="border border-white rounded-full w-20 h-20 flex items-center justify-center text-xl hover:bg-white hover:text-black">Up</a></div>
                </div>
                <div className="grid grid-cols-4 gap-8 text-xl pt-[50px] pb-24 xl:grid-cols-3 lg:grid-cols-1 lg:pb-16">
                    {
                        node.contact.map((n,i)=>{
                            return (
                                <ul key={i} className="grid gap-4">
                                    <li><span>{n.title}</span></li>
                                    <li><A variant="border-black" href={`mailto:${n.email}`}>{n.email}</A></li>
                                    <li><A variant="border-black" href={`tel:${n.telphone}`}>{n.telphone}</A></li>
                                </ul>
                            )
                        })
                    }
                </div>
                <div className="flex justify-between md:flex-col-reverse">
                    <ul className="flex items-center sm:flex-col-reverse">
                        <li className="mr-8 sm:mr-0 sm:w-full sm:pt-10">© Gemfisher {new Date().getFullYear()}</li>
                        <li className="mr-8 sm:mr-0 sm:w-full"><A variant="border-black" href="/cookies">Cookie Policy</A></li>
                        <li className="mr-8 sm:mr-0 sm:w-full"><A variant="border-black" href="/privacy">Privacy Notice</A></li>
                        <li className="sm:w-full"><A variant="border-black" href="https://beian.miit.gov.cn/#/Integrated/index">苏ICP备19017173号-1</A></li>
                    </ul>
                    <ul className="flex items-center md:mb-[30px]">
                        <li className="relative group"><div className="border border-white hover:bg-white hover:text-black rounded-full w-10 h-10 flex items-center justify-center mr-4"><svg width="20" viewBox="0 0 1024 1024" fill="currentColor"><path d="M720 345h10C698 203 548 96 368 96 165 96 0 233 0 401c0 99 57 187 146 243l-37 111 131-69a428 428 0 0 0 156 18c-8-24-12-50-12-76 0-156 151-283 336-283zm-226-99c26 0 48 22 48 48a48 48 0 1 1-96 0c0-26 21-48 48-48zm-254 97c-27 0-48-22-48-49s21-48 48-48 48 22 48 48c0 27-22 49-48 49z"/><path d="M1024 628c0-141-136-257-304-257S415 487 415 628s137 257 305 257c35 0 70-5 102-15l102 58-28-90c78-47 128-123 128-210zm-403-43c-22 0-40-16-40-36s18-36 40-36 40 16 40 36c0 21-18 36-40 36zm199 0c-22 0-40-16-40-36s18-36 40-36 40 16 40 36c0 21-18 36-40 36z"/></svg></div><span className="hidden md:left-20 group-hover:block absolute left-1/2 bottom-0 w-40 h-40 transform -translate-y-12 -translate-x-1/2"><img src={node.weixin}/></span></li>
                        <li><a className="border border-white hover:bg-white hover:text-black rounded-full w-10 h-10 flex items-center justify-center mr-4" href={node.behance} target="_blank"><svg width="24" viewBox="0 0 1024 1024" fill="currentColor"><path d="M437 503c28-33 32-74 32-98 0-118-114-149-175-149H128a43 43 0 0 0-43 43v426a43 43 0 0 0 43 43h213c60 0 150-40 150-149 0-53-18-91-54-116zM171 341h123c4 0 90 1 90 64 0 33-7 64-86 64H171V341zm170 342H171V555h127c107 0 107 37 107 64 0 56-45 63-64 64zm406-342a192 192 0 0 0-192 192v43c0 106 86 192 191 192h3c18 0 92-3 143-53 21-20 34-46 41-75h-90a62 62 0 0 1-10 14c-24 24-68 29-85 29-50 1-96-37-106-86h211v1l48-1a43 43 0 0 0 38-42v-22c0-106-86-192-192-192zM642 512a107 107 0 0 1 209 0H642zm-45-299h256v86H597z"/></svg></a></li>
                        <li><a className="border border-white hover:bg-white hover:text-black rounded-full w-10 h-10 flex items-center justify-center mr-4" href={node.instagram} target="_blank"><svg width="18" viewBox="0 0 1024 1024" fill="currentColor"><path d="M512 92c137 0 153 1 207 3 139 7 204 72 210 210 2 54 3 70 3 207s-1 153-3 207c-7 138-71 204-210 210-54 2-70 3-207 3s-153-1-207-3c-139-7-203-73-210-210-2-54-3-70-3-207s1-153 3-207c7-138 71-203 210-210 54-2 70-3 207-3zm0-92C373 0 356 1 301 3 115 12 12 115 3 301c-2 55-3 72-3 211s1 157 3 211c9 186 112 289 298 298 55 2 72 3 211 3s157-1 211-3c186-9 289-112 298-298 2-54 3-72 3-211s-1-156-3-211C1013 115 909 12 723 3c-55-2-72-3-211-3zm0 249a263 263 0 1 0 0 526 263 263 0 0 0 0-526zm0 434a171 171 0 1 1 0-342 171 171 0 0 1 0 342zm273-506a61 61 0 1 0 0 123 61 61 0 0 0 0-123z"/></svg></a></li>
                        <li><a className="border border-white hover:bg-white hover:text-black rounded-full w-10 h-10 flex items-center justify-center" href={node.weibo} target="_blank"><svg width="18" viewBox="0 0 1024 1024" fill="currentColor"><path d="M916 518q28 22 39 52t10 63-15 65-31 60q-35 48-79 82t-91 56-95 35-90 19-75 8-52 1-49-2-68-10-78-22-80-36-74-55-60-79q-17-30-22-64t-6-86q0-22 8-51t27-64 53-75 86-90q51-49 106-86t117-58q29-10 64-11t57 11l20 23q10 11 12 29 3 15 0 28t-5 27-5 25 2 23q17 2 33-2t32-9 31-12 34-9q27-5 55-4t49 7 36 22 17 39q2 14-2 26t-8 23-7 22 1 24q3 7 15 13t27 12 32 14 29 16zM658 837q27-17 54-45t44-64 19-76-20-82q-20-33-48-57t-63-37-74-20-81-6q-78 0-139 16t-106 43-72 60-39 69q-11 34-7 67t21 63 43 53 58 38q58 26 119 32t116 1 101-22 74-33zM524 513q21 8 39 18t33 28q10 12 18 31t11 39 1 43-10 39q-8 18-20 37t-28 35-41 30-57 18q-61 12-118-11t-88-75q-12-39-13-70t16-67q14-27 41-51t62-39 75-18 79 13zM361 765q38 3 58-19t22-48-16-47-52-17q-28 3-43 23t-18 44 10 42 39 22zM780 6q75 0 140 20t114 58 76 92 28 123q0 34-17 51t-35 17-35-14-17-46q0-41-22-78t-60-64-84-44-97-16q-32 0-47-15t-14-34 19-34 51-16zm0 164q96 0 145 49t49 147q0 30-10 43t-23 11-22-14-11-36q0-61-35-97t-93-35q-19 0-28-11t-10-23 10-23 28-11z"/></svg></a></li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}
